
/**
 * Get branding from primutec.eu with the siteid and add it at the top of the page.
 * 
 */

import '@babel/polyfill/noConflict';
import 'whatwg-fetch';

class Branding {
  constructor() {
    const { hostname } = window.location;

    this.elementID = '';
    this.display = 'logo';
    this.content = '';
    this.endPoint = `https://www.primutec.eu/wp-json/primutec/v1/get-branding?host=${hostname}`;
  }

  init() {
    fetch(this.endPoint, {
    })
      .then((response) => {
        const json = response.json();
        return json;
      })
      .then((data) => {
        if (data.status === 'OK') {
          this.display = data.display;
          this.content = data.html;
          this.elementSelector = data.elementSelector;
        }
      })
      .catch((error) => {
        console.error('Error:', error); // eslint-disable-line no-console
      })
      .finally(() => {
        if (!this.content.length) {
          return;
        }

        const branding = this.htmlToElement(this.content);

        if (this.display === 'bar') {
          Branding.addBar(branding);
        } else {
          this.addLogo(branding);
        }
      });
  }

  addLogo(branding) {
    if (!this.elementSelector) {
      return;
    }

    const element = document.querySelector(this.elementSelector);

    if (!element) {
      return;
    }

    element.append(branding);
  }

  static addBar(branding) {
    const body = document.querySelector('body');

    body.prepend(branding);

    if (!window.IntersectionObserver) {
      return;
    }

    if (branding.dataset.compatibility === 'not-hide') {
      return;
    }

    const brandingObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          // eslint-disable-next-line
          entry.target.classList.add('hide-primutec-branding'); 
        } else {
          // eslint-disable-next-line
          entry.target.classList.remove('hide-primutec-branding');
        }
      });
    });

    brandingObserver.observe(branding);
  }

  htmlToElement(html) { // eslint-disable-line class-methods-use-this
    const div = document.createElement('div');
    div.innerHTML = html.trim();
    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const branding = new Branding();
  branding.init();
});
